<template>
  <div class="agent-dashboard agent-wrapper-dashboard">
    <div id="mapboxLeaflet">
      <div class="leaflet-control coordinate" />
      <div
        v-if="!isAgentOnline"
        class="map-bottom-offline"
      >
        <!--temporary display none-->
        <div class="d-flex align-items-center h-100">
          <div class="moon-image-block">
            <img
              class="moon-image"
              :src="require('@/assets/images/app-icons/moon.png')"
            >
          </div>
          <div class="offline-text-block ml-1">
            <div class="offline-text">
              {{ $t('You are offline!') }}
            </div>
            <div class="go-online-text">
              {{ $t('Go online to start accepting jobs') }}.
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isAgentOnline"
        class="map-bottom-offline bg-white"
        :class="{ 'details-on-map': detailsOnMap }"
      >
        <div
          v-if="!detailsOnMap"
          class="d-flex align-items-center h-100"
        >
          <div class="moon-image-block">
            <img
              class="moon-image"
              :src="require('@/assets/images/app-icons/order.png')"
            >
          </div>
          <div class="offline-text-block ml-1">
            <div
              class="offline-text cursor-pointer playfair-heading text-uppercase font-18"
              @click="toggleIncomingOrders"
            >
              {{ $t('Check incoming orders') }}
            </div>
          </div>
        </div>
        <div v-else>
          <agent-request-card
            v-if="!isProviderAgent"
            :item="detailsOnMap"
            :that="that"
            :show-in-map="true"
          />
          <agent-service-request-card
            v-if="isProviderAgent"
            :item="detailsOnMap"
            :that="that"
            :show-in-map="true"
          />
        </div>
      </div>
      <!--Offline block end-->
    </div>
    <b-modal
      id="agentOrderModal"
      v-model="viewOrderDetailModal"
      :no-close-on-backdrop="true"
      :title="$t('Order Details')"
      hide-footer
      @hidden="viewOrderDetailModal = false"
    >
      <order-info :order-detail="orderDetail" />
    </b-modal>
    <b-modal
      id="incomingAgentCardsModal"
      v-model="showIncomingOrders"
      :no-close-on-backdrop="true"
      :title="$t('Incoming Orders') + ' (' + incomingOrderRequest.length + ')'"
      hide-footer
      @hidden="showIncomingOrders = false"
    >
      <template #modal-title>
        <b-button variant="outline-primary" size="sm">
          Incoming Orders <b-badge variant="primary" class="ml-1">{{ incomingOrderRequest.length }}</b-badge>
        </b-button>
      </template>
      <div
        v-if="showIncomingOrders"
        class="agent-cards-modal wrap-agent-cards main-card-block"
      >
        <template v-for="(item, index) of incomingOrderRequest">
          <agent-request-card
            v-if="!isProviderAgent"
            :key="index"
            :item="item"
            :show-timer="true"
            :that="that"
          />
          <agent-service-request-card
            v-if="isProviderAgent"
            :key="index"
            :item="item"
            :show-timer="true"
            :that="that"
          />
        </template>
        <p
          v-if="incomingOrderRequest.length === 0"
          class="text-center"
        >
          {{ $t('There are no incoming orders') }}
        </p>
      </div>
    </b-modal>
    <b-modal
      id="agentCardsModal"
      v-model="openFullScreen"
      :no-close-on-backdrop="true"
      :title="$t('Current Orders') + ' (' +  + ')'"
      hide-footer
      @hidden="openFullScreen = false"
    >
      <template #modal-title>
        <b-button variant="outline-primary" size="sm">
          Current Orders <b-badge variant="primary" class="ml-1">{{ agentRequest.length }}</b-badge>
        </b-button>
      </template>
      <div
        v-if="openFullScreen"
        class="agent-cards-modal wrap-agent-cards main-card-block"
      >
        <template v-for="(item, index) of agentRequest">
          <agent-request-card
            v-if="!isProviderAgent"
            :key="index"
            :item="item"
            :index="index"
            :that="that"
            :order-detail="orderDetail"
            :show-ignore="false"
            @hide-orders-modal="openFullScreen = false"
          />
          <agent-service-request-card
            v-if="isProviderAgent"
            :key="index"
            :item="item"
            :index="index"
            :that="that"
            :show-ignore="false"
            @hide-orders-modal="openFullScreen = false"
          />
        </template>
        <p
          v-if="agentRequest.length === 0"
          class="text-center"
        >
          {{ $t('There are no orders in progress') }}
        </p>
      </div>
    </b-modal>
    <b-modal
      v-model="showOtpForm"
      :no-close-on-backdrop="true"
      :title="$t('Upload Before Image and Enter OTP')"
      hide-footer
      @hidden="showOtpForm = false"
    >
      <div class="col-md-12">
        <b-form-group :label="$t('Enter OTP')">
          <b-form-input v-model="otpNumber" />
        </b-form-group>
      </div>
      <div class="col-md-12">
        <b-form-group :label="$t('Before Image')">
          <image-input
            class="back-image"
            @input-file="beforeImageUpload"
          />
        </b-form-group>
      </div>
      <div class="col-md-12 text-center">
        <button
          class="btn btn-primary"
          @click="agentUpdateOrder"
        >
          {{ $t('Submit') }}
        </button>
      </div>
    </b-modal>
    <b-modal
      v-model="showAfterImagePopup"
      :no-close-on-backdrop="true"
      :title="$t('Upload After Image')"
      hide-footer
      @hidden="showAfterImagePopup = false"
    >
      <div class="col-md-12">
        <b-form-group :label="$t('After Image')">
          <image-input
            class="back-image"
            @input-file="afterImageUpload"
          />
        </b-form-group>
      </div>
      <div class="col-md-12 text-center">
        <button
          class="btn btn-primary"
          @click="agentUpdateOrder"
        >
          {{ $t('Submit') }}
        </button>
      </div>
    </b-modal>
    <b-modal
      id="viewReviewModal"
      v-model="viewReviewModal"
      :no-close-on-backdrop="true"
      hide-footer
      @hidden="viewReviewModal = false"
    >
      <div class="feedback w-100 my-2">
        <label style="font-size: 15px">
          <img
            style="width: 20px"
            :src="require('@/assets/images/app-icons/feedback@2x.png')"
          >
          {{ $t('Please provide your feedback') }}
        </label>
        <div class="feedback-wrapper w-100">
          <img
            class="w-50 mx-auto my-2 d-block"
            :src="require('@/assets/images/app-icons/undraw_personal_opinions_re_qw29.png')"
          >
        </div>
        <div class="wrap-ratings d-flex justify-content-center my-1">
          <img
            v-for="star in 5"
            :key="star"
            style="height: 25px"
            :class="{ 'gray-scale-icon': star > ratingPoint }"
            class="cursor-pointer"
            :src="require('@/assets/images/app-icons/rating.png')"
            @click="ratingPoint = star"
          >
        </div>
        <textarea
          v-model="storeReviewText"
          :placeholder="$t('Post a comment')"
          class="form-control"
        />
        <div class="col-md-12 text-center mt-2">
          <button
            class="btn btn-primary"
            @click="postReview"
          >
            {{ $t('Post Comment') }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="chat-user-modal-popup-navbar" v-model="modals.chat.open" :no-close-on-backdrop="true" hide-footer
      @hidden="modals.chat.open = false">
      <chat 
        :admin_service="modals.chat.admin_service" 
        :orderable_id="modals.chat.id"
        :to_userable_type="modals.chat.to_userable_type" 
        :to_userable_id="modals.chat.to_userable_id" 
        :modalState="modals.chat.open"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'
import OrderInfo from './agent/OrderInfo.vue'
import AgentRequestCard from './shared/AgentRequestCard.vue'
import Chat from '../shared/chat/ChatNew.vue'
import { useStoreUi } from '../store/useStore'
import { useProviderUi } from './useProvider'
import AgentServiceRequestCard from './shared/AgentServiceRequestCard.vue'
import ImageInput from '../users/shared/ImageInput.vue'

export default {
  components: {
    OrderInfo,
    Chat,
    BModal,
    BFormGroup,
    BFormInput,
    AgentRequestCard,
    AgentServiceRequestCard,
    ImageInput,
  },
  data() {
    return {
      viewReviewModal: false,
      storeReviewText: '',
      ratingPoint: 1,
      agentRequest: [],
      detailsOnMap: null,
      collapseState: false,
      isAgentOnline: true,
      cancelReason: '',
      incomingOrderRequest: [],
      showIncomingOrders: false,
      openFullScreen: false,
      mapbox: null,
      swiperAgent: null,
      intervalAgent: null,
      agentLocation: {},
      orderAction: null,
      storeOrder: null,
      orderDetail: null,
      showOtpForm: false,
      otpNumber: '',
      storeChatModal: false,
      userChatModal: false,
      currentAgentOrder: null,
      beforePickup: null,
      afterPickup: null,
      viewOrderDetailModal: false,
      mapImageStore: {
        STORE: require('@/assets/images/app-icons/store_New.png'),
        AGENT: require('@/assets/images/app-icons/agent.png'),
        HOUSE: require('@/assets/images/app-icons/house_New.png'),
      },
      showAfterImagePopup: false,
      modals: {
        chat: {
          open: false,
          admin_service: null,
          id: null,
          to_userable_type: null,
          to_userable_id: null,
        },
      },
    }
  },
  computed: {
    agentInfo() {
      return window.SuperApp.getters.userInfo()
    },
    isProviderAgent() {
      return this.agentInfo.parent_type === 'PROVIDER'
    },
    that() {
      return this
    },
    userChatName() {
      return `${this.storeOrder.user.first_name}`
    },
    userStoreName() {
      console.log(this.storeOrder, 'this.storeOrder');
      if (this.storeOrder) {
        if (this.storeOrder.store) {
          return `${this.storeOrder.store.store_name}`
        }
        if (this.storeOrder.provider) {
          return `${this.storeOrder.provider.first_name} ${this.storeOrder.provider.last_name}`
        }
      }
      return ''
    },
    storeProviderId() {
      if (!this.storeOrder) return ''
      if (this.storeOrder.store_id) return this.storeOrder.store_id
      if (this.storeOrder.provider_id) return this.storeOrder.provider_id
      return ''
    },
    storeChatName() {
      const userInfo = window.SuperApp.getters.userInfo()
      if (userInfo) {
        return `${userInfo.first_name} ${userInfo.last_name}`
      }
      return ''
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeOccured)
    if (window.L) {
      this.mapbox = window.L.map('mapboxLeaflet').setView([-41.2858, 174.78682], 8)
      const mapLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
      window.L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: `Map data &copy; ${mapLink}`,
        maxZoom: 18,
      }).addTo(this.mapbox)
    }
    if (document.querySelector('.app-content.content.ecommerce-application')) {
      document.querySelector('.app-content.content.ecommerce-application').classList.add('pr-pl-imp')
    }
    if (document.getElementById('app')) {
      document.getElementById('app').classList.add('agent-web-header')
    }
    eventBus.$on('agent-status-changed', this.agentStatusChanged)
    eventBus.$on('open-full-screen-view', this.toggleFullScreen)
    eventBus.$on('open-incoming-orders', this.toggleIncomingOrders)
    eventBus.$on('show-details-on-map', this.showDetailsOnMap)
    eventBus.$on('reload-agent-orders', reason => {
      this.cancelReason = reason
      this.agentUpdateOrder()
    })
    this.getAgentOrders()
    this.getCurrentLocation()
    this.incomingOrders()
    this.openFullScreen = true
    this.intervalAgent = setInterval(() => {
      this.getCurrentLocation()
    }, 1000 * 15)
    eventBus.$on('refresh-agent-details-with-order', () => {
      this.getAgentOrders()
      this.incomingOrders()
    })
  },
  destroyed() {
    if (document.querySelector('.app-content.content.ecommerce-application')) {
      document.querySelector('.app-content.content.ecommerce-application').classList.remove('pr-pl-imp')
    }
    if (document.getElementById('app')) {
      document.getElementById('app').classList.remove('agent-web-header')
    }
    if (window.agentRoutes) {
      window.agentRoutes = null
    }
    if (this.intervalAgent) {
      clearInterval(this.intervalAgent)
    }
    window.removeEventListener('resize', this.resizeOccured)
    eventBus.$off('reload-agent-orders', this.getAgentOrders)
    eventBus.$off('agent-status-changed', this.agentStatusChanged)
    eventBus.$off('open-full-screen-view', this.toggleFullScreen)
    eventBus.$off('open-incoming-orders', this.toggleIncomingOrders)
    eventBus.$off('show-details-on-map', this.showDetailsOnMap)
    eventBus.$off('refresh-agent-details-with-order')
  },
  methods: {
    beforeImageUpload(payload) {
      this.beforePickup = payload
    },
    afterImageUpload(payload) {
      this.afterPickup = payload
    },
    runDirection(start, end, agent) {
      const urlRoutes = []
      if (start) urlRoutes.push(start)
      if (end) urlRoutes.push(end)
      if (agent) urlRoutes.push(agent)
      if (this.mapbox) {
        this.mapbox.remove()
        this.mapbox = null
        window.agentRoutes = null
      }
      if (!this.mapbox && window.L) {
        this.mapbox = window.L.map('mapboxLeaflet').setView([-41.2858, 174.78682], 8)
        const mapLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
        window.L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: `Map data &copy; ${mapLink}`,
          maxZoom: 18,
        }).addTo(this.mapbox)
      }
      if (urlRoutes.length >= 2 && this.mapbox) {
        window.SuperApp.actions.createRouting(urlRoutes, [this.mapImageStore.STORE, this.mapImageStore.HOUSE, this.mapImageStore.AGENT], this.mapbox, 'agentRoutes')
      }
    },
    showDetailsOnMap(payload) {
      this.detailsOnMap = payload
      if (window.agentRoutes && window.agentRoutes.remove) {
        window.agentRoutes.remove()
      }
      if (!this.detailsOnMap) {
        this.toggleFullScreen()
      }
      if (this.detailsOnMap && this.detailsOnMap.pickup && this.detailsOnMap.delivery) {
        const agentLocations = this.detailsOnMap.agent ? `${this.detailsOnMap.agent.latitude}, ${this.detailsOnMap.agent.longitude}` : ''
        this.runDirection(
          `${this.detailsOnMap.pickup.latitude}, ${this.detailsOnMap.pickup.longitude}`,
          `${this.detailsOnMap.delivery.latitude}, ${this.detailsOnMap.delivery.longitude}`,
          agentLocations,
        )
      }
    },
    setChatInfo(item, toData) {
      this.modals.chat = {
        admin_service: item.admin_service,
        id: item.id,
        to_userable_type: toData.to_userable_type,
        to_userable_id: toData.to_userable_id,
        open: true
      }
    },
    toggleFullScreen() {
      this.openFullScreen = true
      this.getAgentOrders()
    },
    toggleIncomingOrders() {
      this.showIncomingOrders = true
      this.incomingOrders()
    },
    agentStatusChanged(isAgentOnline) {
      this.isAgentOnline = isAgentOnline
    },
    actionEvent(eventName) {
      if (eventName === 'entered' && this.mapbox) {
        this.mapbox.dragging.disable()
      } else if (eventName === 'removed' && this.mapbox) {
        this.mapbox.dragging.enable()
      }
    },
    resizeOccured() {
      if (window.innerWidth > 767) {
        this.collapseState = false
      }
    },
    openViewOrder(props) {
      this.orderDetail = props
      this.viewOrderDetailModal = true
    },
    updateRequestApproval(props, nextStep = '') {
      this.orderAction = props
      this.orderAction.nextStatus = nextStep
      if (nextStep === 'REJECTED' || nextStep === 'DELIVERY_REJECTED') {
        this.$swal({
          title: this.$t('You want to cancel this request?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            eventBus.$emit('show-cancel-agent-request', props)
          }
        })
        return
      }
      if (!nextStep) {
        this.$swal({
          title: this.$t('You want to accept this request?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acceptOrderRequest()
          }
        })
      } else {
        this.updateAgentOrder()
      }
    },
    ignoreService(props){
      this.orderAction = props
      this.$swal({
        title: this.$t('You want to ignore this request?'),
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.ignoreServiceRequest()
        }
      })
    },
    ignoreServiceRequest() {
      showLoader()
      const { ignoreService } = useProviderUi()
      ignoreService(this.orderAction.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message ? data.message : data.responseData.message)
            this.getAgentOrders()
            this.incomingOrders()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    ignoreOrder(props, skipConfirmation) {
      this.orderAction = props
      if(skipConfirmation === true){
        this.ignoreOrderRequest()
      }else{
        this.$swal({
          title: this.$t('You want to ignore this request?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.ignoreOrderRequest()
          }
        })
      }
    },
    ignoreOrderRequest() {
      showLoader()
      const { ignoreOrder } = useProviderUi()
      ignoreOrder(this.orderAction.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message ? data.message : data.responseData.message)
            this.getAgentOrders()
            this.incomingOrders()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    acceptOrderRequest() {
      showLoader()
      const { acceptOrder, acceptService } = useProviderUi()
      const callType = this.isProviderAgent ? acceptService : acceptOrder
      callType(this.orderAction.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData.status === 422) {
              showDangerNotification(this, data.message ? data.message : data.responseData.message)
            } else {
              eventBus.$emit('refresh-on-going-services-socket', {
                userId: this.orderAction.user_id,
                orderId: this.orderAction.id,
                serviceId: this.orderAction.id,
                service: this.orderAction.admin_service,
              })
              if (this.orderAction.store_id) {
                eventBus.$emit('store-sync-app', {
                  refreshDashboard: true,
                  storeId: this.orderAction.store_id,
                })
              }
              if (this.orderAction.provider_id) {
                eventBus.$emit('provider-sync-app', {
                  refreshDashboard: true,
                  providerId: this.orderAction.provider_id,
                })
              }
              showSuccessNotification(this, data.message ? data.message : data.responseData.message)
              this.getAgentOrders()
              this.incomingOrders()
            }
          } else if (data.message) {
            showDangerNotification(this, data.message)
          } else if (data.responseData.message) {
            showDangerNotification(this, data.responseData.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    updateAgentOrder(props) {
      let title = this.$t('You want to update this order?');
      if(this.orderAction.nextStatus === 'COMPLETED' || this.orderAction.nextStatus === 'DELIVERED'){
        title = this.$t('Did you collect the cash from Customer?');
      }
      this.$swal({
        title,
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.isProviderAgent) {
            if (this.orderAction.nextStatus === 'PICKEDUP') {
              this.showOtpForm = true
            } else if (this.orderAction.nextStatus === 'DROPPED') {
              this.showAfterImagePopup = true
            } else if (this.orderAction.nextStatus === 'REJECTED') {
              eventBus.$emit('show-cancel-agent-request', props)
            } else {
              this.agentUpdateOrder()
            }
          } else {
            this.agentUpdateOrder()
          }
        }
      })
    },
    agentSendOtp() {
      const { agentSendOtp } = useProviderUi()
      const formData = new FormData()
      formData.append('id', this.orderAction.id)
      agentSendOtp(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Please take OTP from user to start it')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    completeOrder() {
      showLoader()
      const { agentUpdateOrder, agentUpdateService } = useProviderUi()
      const callType = this.orderAction.admin_service === 'ORDER' ? agentUpdateOrder : agentUpdateService
      const formData = new FormData()
      formData.append('id', this.orderAction.id)
      formData.append('admin_service', this.orderAction.admin_service)
      formData.append('status', 'COMPLETED')
      callType(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.updateNotifications()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    showReviewModal() {
      this.viewReviewModal = true
    },
    postReview() {
      const { agentUpdateOrderReview, agentUpdateServiceReview } = useProviderUi()
      const callType = this.orderAction.admin_service === 'ORDER' ? agentUpdateOrderReview : agentUpdateServiceReview
      const formData = new FormData()
      showLoader()
      formData.append('id', this.orderAction.id)
      formData.append('rating', this.ratingPoint)
      formData.append('comment', this.storeReviewText)
      callType(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.viewReviewModal = false
            showSuccessNotification(this, this.$t('Review updated successfully'))
            this.ratingPoint = 1
            this.storeReviewText = ''
            this.updateNotifications()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    updateNotifications() {
      eventBus.$emit('refresh-on-going-services-socket', {
        userId: this.orderAction.user_id,
        orderId: this.orderAction.id,
        serviceId: this.orderAction.id,
        service: this.orderAction.admin_service,
      })
      if (this.orderAction.store_id) {
        eventBus.$emit('store-sync-app', {
          refreshDashboard: true,
          storeId: this.orderAction.store_id,
        })
      }
      if (this.orderAction.provider_id) {
        eventBus.$emit('provider-sync-app', {
          refreshDashboard: true,
          providerId: this.orderAction.provider_id,
        })
      }
      this.getAgentOrders()
      this.incomingOrders()
    },
    agentUpdateOrder() {
      showLoader()
      const { agentUpdateOrder, agentUpdateService } = useProviderUi()
      const callType = this.orderAction.admin_service === 'ORDER' ? agentUpdateOrder : agentUpdateService
      const formData = new FormData()
      formData.append('id', this.orderAction.id)
      formData.append('admin_service', this.orderAction.admin_service)
      formData.append('status', this.orderAction.nextStatus)
      if (this.cancelReason && this.orderAction.nextStatus === 'REJECTED') {
        formData.append('cancel_reason', this.cancelReason)
        formData.append('cancelled_by', 'AGENT')
      }
      if (this.orderAction.nextStatus === 'PICKEDUP') {
        formData.append('otp', this.otpNumber)
        formData.append('before_picture', this.beforePickup)
      }
      if (this.orderAction.nextStatus === 'DROPPED') {
        formData.append('after_picture', this.afterPickup)
      }
      callType(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, this.$t('Order updated successfully'))
            eventBus.$emit('refresh-on-going-services-socket', {
              userId: this.orderAction.user_id,
              orderId: this.orderAction.id,
              serviceId: this.orderAction.id,
              service: this.orderAction.admin_service,
            })
            if (this.orderAction.store_id) {
              eventBus.$emit('store-sync-app', {
                refreshDashboard: true,
                storeId: this.orderAction.store_id,
              })
            }
            if (this.orderAction.provider_id) {
              eventBus.$emit('provider-sync-app', {
                refreshDashboard: true,
                providerId: this.orderAction.provider_id,
              })
            }
            if (this.orderAction.nextStatus === 'DELIVERED') {
              this.completeOrder()
            }
            if (this.orderAction.nextStatus === 'COMPLETED' || this.orderAction.nextStatus === 'DELIVERED') {
              this.showReviewModal()
            }
            this.showOtpForm = false
            this.showAfterImagePopup = false
            this.getAgentOrders()
            this.incomingOrders()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getDeliveryAddress(payload) {
      if (!payload.delivery) return ''
      const address = []
      address.push(payload.delivery.flat_no)
      address.push(payload.delivery.building)
      address.push(payload.delivery.street)
      address.push(payload.delivery.map_address)
      return address.filter(x => x).join(', ')
    },
    incomingOrders() {
      showLoader()
      const { incomingOrders, incomingServices } = useProviderUi()
      const currentType = this.isProviderAgent ? incomingServices : incomingOrders
      currentType('?page=all')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.incomingOrderRequest = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getAgentOrders() {
      showLoader()
      const { currentOrders, currentServices } = useProviderUi()
      const currentType = this.isProviderAgent ? currentServices : currentOrders
      currentType('?page=all')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.agentRequest = data.responseData.data ? data.responseData.data : data.responseData
            if (this.detailsOnMap && this.agentRequest.find(x => x.id === this.detailsOnMap.id)) {
              this.detailsOnMap = this.agentRequest.find(x => x.id === this.detailsOnMap.id)
            } else {
              this.detailsOnMap = null
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getCurrentLocation() {
      const { getLocationInfo } = useStoreUi()

      getLocationInfo(`?agent_id=${this.agentInfo.id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (!this.agentLocation) {
              this.agentLocation = {}
            }
            window.mapbox = this.mapbox
            if (window.agentRoutes && window.agentRoutes.remove) {
              window.agentRoutes.remove()
            }
            if (data.responseData && data.responseData.latitude !== this.agentLocation.latitude && data.responseData.longitude !== this.agentLocation.longitude) {
              if (window.individualMarker && Array.isArray(window.individualMarker)) {
                window.individualMarker.forEach(item => item.remove())
                window.individualMarker = []
              }
              this.agentLocation = data.responseData
              if (this.agentLocation && this.agentLocation.latitude && this.agentLocation.longitude) {
                if (this.detailsOnMap && this.detailsOnMap.pickup && this.detailsOnMap.delivery) {
                  this.runDirection(
                    `${this.detailsOnMap.pickup.latitude}, ${this.detailsOnMap.pickup.longitude}`,
                    `${this.detailsOnMap.delivery.latitude}, ${this.detailsOnMap.delivery.longitude}`,
                    `${this.agentLocation.latitude}, ${this.agentLocation.longitude}`,
                  )
                } else {
                  window.SuperApp.actions.addMarker([`${this.agentLocation.latitude}, ${this.agentLocation.longitude}`], [this.mapImageStore.AGENT], this.mapbox, 'individualMarker')
                }
              }
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/agents.scss';
.leaflet-container{
  font-family: 'Lato', "Roboto slab"
}
.agent-wrapper-dashboard .details-on-map {
  height: auto !important;

  .swiper-slide {
    margin: 0;
  }
}

#viewReviewModal .feedback .gray-scale-icon {
  filter: grayscale(1);
}
</style>
